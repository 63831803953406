import React from "react";

import { Avatar, Col, Card, List, Space } from "antd";
import { Link } from "gatsby";
import "src/components/productCategories/style.scss";

import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";

import interiorBuganvilla from "src/components/referencias/images/interior/climateCoating-thermoPlus-adosado-buganvilla-thumbnail.jpg";

import lcFirst from "utils/lcFirst";

import thermoPlusAvatarImg from "src/components/products/images/thermoPlusAvatar.jpg";

const { Meta } = Card;

export const ProductCategoryExteriorComponent = ({}) => {
  const listData = [
    {
      title: "ThermoPlus - Aplicación en vivienda adosada",
      list: ["Salubridad en ambiente con niños", "Confort"],
      imgSrc: interiorBuganvilla,
      productName: "ThermoPlus",
      avatar: thermoPlusAvatarImg,
      productTitle: "ThermoPlus",
      description: "Aplicación en zonas comunes y dormitorios",
      content:
        "Los dueños de la vivienda aquejados de alergias y con niños mostraban especial interés en un producto ecológico y que pudiese en la medida de lo posible aumentar su confort",
      // linkToReferencia:
      //   "/referencias-humedades/paredes-interiores-afectadas-por-moho-humedades-pamplona",
    },
  ];

  const IconText = ({ icon, text, linkToReferencia }) => (
    <Space>
      {linkToReferencia ? (
        <Link className="thermoActive bold" to={linkToReferencia}>
          {React.createElement(icon)}
          {"  "}
          {text}
        </Link>
      ) : (
        <>
          {React.createElement(icon)}
          {text}
        </>
      )}
    </Space>
  );

  return (
    <div className="ccContent referencias">
      <List
        {...{
          itemLayout: "vertical",
          size: "large",
          pagination: {
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          },
          dataSource: listData,
          footer: (
            <div>
              Referencias de proyectos que han usado{" "}
              <b>ClimateCoating en España</b>
            </div>
          ),

          renderItem: ({
            title,
            list,
            avatar,
            productName,
            description,
            content,
            imgSrc,
            linkToReferencia,
          }) => (
            <List.Item
              key={title}
              actions={list
                .map((feature, key) => (
                  <IconText
                    {...{
                      icon: CheckOutlined,
                      text: feature,
                      key,
                    }}
                  />
                ))
                .concat(
                  linkToReferencia ? (
                    <IconText
                      {...{
                        icon: PlusCircleOutlined,
                        text: "Más Info",
                        key: "masInfo",
                        linkToReferencia,
                      }}
                    />
                  ) : (
                    []
                  )
                )}
              extra={<img width={272} alt="logo" src={imgSrc} />}
            >
              <List.Item.Meta
                avatar={<Avatar src={avatar} />}
                title={
                  <Link
                    to={`/productos/${productName.toLowerCase()}`}
                    className={lcFirst(productName)}
                  >
                    {title}
                  </Link>
                }
                description={description}
              />
              {content}
            </List.Item>
          ),
        }}
      />
    </div>
  );
};

export default ProductCategoryExteriorComponent;
