import React from "react";

import ReferenciasInterior from "src/components/referencias/Interior";
import { LayoutReferencia } from "src/layout/LayoutReferencia";

const ReferenciasInteriorPage = (props) => (
  <LayoutReferencia
    {...{
      activePage: "interiores",
      pageTitle: "Referencias de ClimateCoating para Interiores",
      pageTitleJSX: (
        <span>
          <span className="mainTitle colored thermoVital">
            Confort y salubridad:
          </span>
          <span className="secondaryTitle">
            Referencias en España de ClimateCoating para Interiores
          </span>
        </span>
      ),
      pageName: "Interiores",
      pageDescription:
        "Referencias en España de ClimateCoating para Interiores: ThermoPlus y ThermoVital",
    }}
  >
    <ReferenciasInterior
      {...{
        xs: 1,
        sm: 2,
        xl: 4,
        xxl: 4,
      }}
    />
  </LayoutReferencia>
);

export default ReferenciasInteriorPage;
